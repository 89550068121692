import { MediaQueryProvider } from '../contexts/MediaQueryContext';
import { Provider } from 'react-redux';
import store from '../store';

const Providers = ({ children }) => (
  <Provider store={store}>
    <MediaQueryProvider>{children}</MediaQueryProvider>
  </Provider>
);

Providers.propTypes = {
  children: PropTypes.node,
};

export default Providers;
