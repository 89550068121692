import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import QueryStatus from '../utils/QueryStatus';
import SinglePane from '../components/base/layout/SinglePane';
import Spinner from '../components/spinner/Spinner';
import useFirebaseCollection from '../hooks/useFirebaseCollection';
import UserListing from '../components/users/UserListing';
import userService from '../services/userService';

function FriendsPage() {
  /* -- Hooks -- */
  const { groups } = useSelector((state) => state.currentUser);

  const queryFriends = useMemo(() => userService.getFriends(groups), [groups]);

  const { data, status } = useFirebaseCollection(queryFriends);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  switch (status) {
    case QueryStatus.LOADING:
      return <Spinner />;

    case QueryStatus.ERROR:
      return <div>Error!</div>;

    default: {
      const users =
        data &&
        data.docs.map((doc) => {
          const { name } = doc.data();

          return {
            id: doc.id,
            name,
          };
        });

      return <SinglePane>{users && <UserListing users={users} />}</SinglePane>;
    }
  }
}

export default FriendsPage;
