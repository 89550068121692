import { Box } from '../base/Box';
import { MediaQueryContext } from '../../contexts/MediaQueryContext';
import { UnstyledButton } from '../base/Buttons';
import { useContext, useEffect, useState } from 'react';
import spacing from '../base/mixins/spacing';
import styled from 'styled-components';

const Caret = styled.span`
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid var(--primary-color, #ffffff);
  display: inline-block;
  margin-top: 0.5rem;
`;

const CenteredUnstyledButton = styled(UnstyledButton)`
  align-items: center;
  display: flex;
  justify-content: center;
`;

const DropdownMenu = styled.div`
  background-color: var(--default-bg-color, #ffffff);
  border-radius: 4px;
  box-shadow: var(--box-shadow--1, 0 2px 5px 0 #aaaaaa);
  color: var(--default-color, #222222);
  display: ${(props) => (props.isHidden ? 'none' : 'block')};
  font-size: 1rem;
  overflow: hidden;
  position: absolute;
  right: 0;

  ${spacing}
`;

const Title = styled.span`
  margin-right: 0.5rem;
`;

function Dropdown({ children, icon: Icon, title }) {
  const [isHidden, setIsHidden] = useState(true);
  const { isSmallScreen } = useContext(MediaQueryContext);

  useEffect(() => {
    const hideDropdown = () => setIsHidden(true);

    if (!isHidden) {
      document.addEventListener('click', hideDropdown, { once: true });
    }

    return () => document.removeEventListener('click', hideDropdown);
  });

  const handleDropdownClick = (e) => {
    if (isHidden) {
      e.stopPropagation();
    }

    setIsHidden(!isHidden);
  };

  let titleEl = (
    <>
      <Title>{title}</Title>
      <Caret />
    </>
  );

  if (Icon && isSmallScreen) {
    titleEl = <Icon />;
  }

  return (
    <Box data-testid="dropdown" position="relative">
      <CenteredUnstyledButton onClick={handleDropdownClick} type="button">
        {titleEl}
      </CenteredUnstyledButton>

      <DropdownMenu isHidden={isHidden} py={2}>
        {children}
      </DropdownMenu>
    </Box>
  );
}

Dropdown.defaultProps = {
  icon: null,
  title: '',
};

Dropdown.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  icon: PropTypes.func,
  title: PropTypes.string,
};

export default Dropdown;
