const CurrentUserActionType = {
  CLEAR_CURRENT_USER: 'current_user:CLEAR',
  SET_CURRENT_USER: 'current_user:SET',
};

/* --------------------- */
/* -- Reducer Actions -- */
/* --------------------- */
const clearCurrentUser = () => ({ type: CurrentUserActionType.CLEAR_CURRENT_USER });

const setCurrentUser = (currentUser) => ({
  currentUser,
  type: CurrentUserActionType.SET_CURRENT_USER,
});

export { clearCurrentUser, CurrentUserActionType, setCurrentUser };
