import { logOut } from '../../actions/authentication';
import { ProfileIcon } from '../base/Icons';
import { setModal } from '../../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import Dropdown from '../dropdown/Dropdown';
import DropdownItem from '../dropdown/DropdownItem';
import PasswordForm from '../password-form/PasswordForm';
import UnstyledLink from '../base/unstyled/UnstyledLink';

function HeaderActions() {
  const { name } = useSelector((state) => state.currentUser);
  const dispatch = useDispatch();
  const isOnLogin = useMatch('/login');
  const isOnSignup = useMatch('/signup');

  const handleChangePasswordClick = () => {
    dispatch(setModal(PasswordForm));
  };

  const handleLogoutClick = () => {
    dispatch(logOut());
  };

  const handleToggleTheme = () => {
    document.body.classList.toggle('dark');

    const themeColor = getComputedStyle(document.body).getPropertyValue('--primary-bg-color');

    document.querySelector('meta[name=theme-color]').setAttribute('content', themeColor);

    localStorage.setItem('isDarkTheme', document.body.classList.contains('dark'));
  };

  if (isOnLogin) {
    return (
      <small>
        <UnstyledLink to="signup">Sign Up</UnstyledLink>
      </small>
    );
  }

  if (isOnSignup) {
    return (
      <small>
        <UnstyledLink to="login">Log In</UnstyledLink>
      </small>
    );
  }

  // We're on a non-existent page and not logged in
  if (!name) {
    return null;
  }

  return (
    <Dropdown icon={ProfileIcon} title={name}>
      <DropdownItem onClick={handleChangePasswordClick}>Change Password</DropdownItem>
      <DropdownItem onClick={handleToggleTheme}>Toggle Theme</DropdownItem>
      <DropdownItem onClick={handleLogoutClick}>Logout</DropdownItem>
    </Dropdown>
  );
}

export default HeaderActions;
