import { AddIcon } from '../components/base/Icons';
import { Box } from '../components/base/Box';
import { setModal } from '../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import { useMemo } from 'react';
import Fab from '../components/base/Fab';
import GroupForm from '../components/groups/GroupForm';
import GroupListing from '../components/groups/GroupListing';
import groupService from '../services/groupService';
import QueryStatus from '../utils/QueryStatus';
import SinglePane from '../components/base/layout/SinglePane';
import Spinner from '../components/spinner/Spinner';
import useFirebaseCollection from '../hooks/useFirebaseCollection';

function GroupPage() {
  /* -- Hooks -- */
  const currentUserId = useSelector((state) => state.currentUser.id);
  const dispatch = useDispatch();

  const groupQuery = useMemo(() => groupService.getUserGroups(currentUserId), [currentUserId]);

  const { data, status } = useFirebaseCollection(groupQuery);

  /* -- Event Handlers -- */
  const handleFabClick = () => dispatch(setModal(GroupForm));

  /* -- Rendering -- */
  switch (status) {
    case QueryStatus.LOADING:
      return <Spinner />;

    case QueryStatus.ERROR:
      return <div>Error!</div>;

    default: {
      const groups = data.docs.map((doc) => {
        const { name } = doc.data();

        return {
          id: doc.id,
          name,
        };
      });

      return (
        <SinglePane>
          <Box mb="64px">
            <GroupListing groups={groups} />
          </Box>

          <Fab extended onClick={handleFabClick}>
            <AddIcon />
            <span>Create Group</span>
          </Fab>
        </SinglePane>
      );
    }
  }
}

export default GroupPage;
