import { Box, FlexBox } from '../base/Box';
import { Button, DangerButton } from '../base/Buttons';
import { clearCurrentItem } from '../../actions/currentItem';
import { clearModal } from '../../actions/modal';
import { deleteItem } from '../../actions/itemList';
import { useDispatch, useSelector } from 'react-redux';

function ConfirmDelete() {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.currentItem);

  const handleCancelClick = () => {
    dispatch(clearCurrentItem());
    dispatch(clearModal());
  };

  const handleConfirmClick = () => {
    dispatch(deleteItem(item.id));
  };

  return (
    <div data-testid="confirm-delete">
      <Box mb={3}>
        <h3>Are you sure you want to delete this item?</h3>
      </Box>

      <FlexBox justifyContent="flex-end">
        <DangerButton mr={3} onClick={handleConfirmClick} type="button">
          Yes
        </DangerButton>
        <Button onClick={handleCancelClick} type="button">
          No
        </Button>
      </FlexBox>
    </div>
  );
}

export default ConfirmDelete;
