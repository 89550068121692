import { FlexBox } from '../base/Box';
import { useMemo } from 'react';
import ItemListingCategory from './ItemListingCategory';
import itemService from '../../services/itemService';
import ListCategories from '../../utils/ListCategories';
import QueryStatus from '../../utils/QueryStatus';
import Spinner from '../spinner/Spinner';
import styled from 'styled-components';
import useFirebaseCollection from '../../hooks/useFirebaseCollection';

const SpinnerContainer = styled(FlexBox)`
  --hollow-dots-spinner__color: var(--primary-color);

  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: flex-start;
  }
`;

function filterItemsIntoCategories(itemList) {
  const likes = {};
  const needs = {};
  const wants = {};

  itemList.forEach((i) => {
    switch (i.category) {
      case ListCategories.LIKE:
        likes[i.id] = i;

        break;

      case ListCategories.NEED:
        needs[i.id] = i;

        break;

      case ListCategories.WANT:
        wants[i.id] = i;

        break;

      default:
        console.error(`Unknown Category: ${i.category}`);

        likes[i.id] = i;
    }
  });

  return { likes, needs, wants };
}

function ItemListing({ userId }) {
  /* -- Hooks -- */
  const userItemQuery = useMemo(() => itemService.getUserItems(userId), [userId]);

  const { data, status } = useFirebaseCollection(userItemQuery);

  /* -- Rendering -- */
  let content = null;

  switch (status) {
    case QueryStatus.LOADING:
      content = (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      );

      break;

    case QueryStatus.ERROR:
      content = <div>Error!</div>;

      break;

    default: {
      const itemList = data.docs.map((doc) => ({
        ...doc.data(),
        id: doc.id,
      }));

      const { likes, needs, wants } = filterItemsIntoCategories(itemList);

      content = (
        <>
          <ItemListingCategory data-testid="needs" items={needs} title="Needs" />
          <ItemListingCategory data-testid="wants" items={wants} title="Wants" />
          <ItemListingCategory data-testid="likes" items={likes} title="Likes" />
        </>
      );
    }
  }

  return <div data-testid="item-list">{content}</div>;
}

ItemListing.propTypes = {
  userId: PropTypes.string.isRequired,
};

export default ItemListing;
