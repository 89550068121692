import styled from 'styled-components';

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(300px, 700px) 1fr;
`;

const Container = styled.div`
  grid-column: 2;
  padding: 1rem;

  @media screen and (min-width: 768px) {
    padding: 1rem 0;
  }
`;

const SinglePane = ({ children }) => (
  <Layout>
    <Container>{children}</Container>
  </Layout>
);

SinglePane.propTypes = {
  children: PropTypes.node,
};

export default SinglePane;
