import { useEffect, useState } from 'react';
import QueryStatus from '../utils/QueryStatus';

const defaultOptions = {};

function useFirebaseDocData(docRef, options = defaultOptions) {
  const [data, setData] = useState();
  const [status, setStatus] = useState(QueryStatus.LOADING);

  useEffect(() => {
    setStatus(QueryStatus.LOADING);

    const unsubscribe = docRef.onSnapshot(
      options,
      (snapshot) => {
        setData(snapshot.data());
        setStatus(QueryStatus.SUCCESS);
      },
      (error) => {
        setData(error);
        setStatus(QueryStatus.ERROR);
      },
    );

    return unsubscribe;
  }, [docRef, options]);

  return { data, status };
}

export default useFirebaseDocData;
