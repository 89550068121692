import CreateDoc from './CreateDoc';
import DeleteDoc from './DeleteDoc';
import firebase from './firebase';
import ListenToCollection from './ListenToCollection';
import QueryCollection from './QueryCollection';
import QueryDoc from './QueryDoc';
import UpdateDoc from './UpdateDoc';

class Database {
  constructor() {
    this._db = firebase.firestore();
  }

  doc(path, docId) {
    return this._db.collection(path).doc(docId);
  }

  query(path) {
    return this._db.collection(path);
  }

  createDoc(path) {
    return new CreateDoc(this._db, path);
  }

  deleteDoc(path, docId) {
    return new DeleteDoc(this._db, path, docId);
  }

  listenToCollection(path, callback) {
    return new ListenToCollection(this._db, path, callback);
  }

  queryCollection(path) {
    return new QueryCollection(this._db, path);
  }

  queryDoc(path, docId) {
    return new QueryDoc(this._db, path, docId);
  }

  update(path, docId) {
    return new UpdateDoc(this._db, path, docId);
  }
}

export default new Database();
