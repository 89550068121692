import { addSimpleNotification } from '../../actions/notifications';
import { Form, FormActions, FormHeader, FormInput, FormLabel } from '../base/Form';
import { PrimaryButton } from '../base/Buttons';
import { signUp } from '../../actions/authentication';
import { useDispatch } from 'react-redux';
import { useFormInput } from '../../hooks';
import styled from 'styled-components';

const CustomForm = styled(Form)`
  @media screen and (min-width: 768px) {
    grid-template-columns: 175px 5fr;
  }
`;

const _isInvalid = (name, email, password, confirm) => {
  if (name.trim().length === 0) {
    return 'Name must not be blank';
  }

  if (email.trim().length === 0) {
    return 'Email must not be blank';
  }

  if (password.trim().length === 0) {
    return 'Password must not be blank';
  }

  if (password !== confirm) {
    return 'Passwords must match';
  }

  return null;
};

function SignUpForm() {
  const dispatch = useDispatch();
  const confirm = useFormInput('');
  const email = useFormInput('');
  const name = useFormInput('');
  const password = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    const error = _isInvalid(name.value, email.value, password.value, confirm.value);

    if (error) {
      dispatch(addSimpleNotification(error, true));

      return;
    }

    dispatch(signUp(name.value, email.value, password.value, confirm.value));
  };

  return (
    <CustomForm data-testid="sign-up-form" onSubmit={handleSubmit}>
      <FormHeader>Sign Up</FormHeader>

      <FormLabel htmlFor="name" required>
        First Name
      </FormLabel>

      <FormInput autoFocus data-testid="name" id="name" type="text" {...name} />

      <FormLabel htmlFor="email" required>
        Email
      </FormLabel>

      <FormInput data-testid="email" id="email" type="email" {...email} />

      <FormLabel htmlFor="password" required>
        Password
      </FormLabel>

      <FormInput data-testid="password" id="password" type="password" {...password} />

      <FormLabel htmlFor="confirm" required>
        Confirm Password
      </FormLabel>

      <FormInput data-testid="confirm" id="confirm" type="password" {...confirm} />

      <FormActions>
        <PrimaryButton type="submit">Submit</PrimaryButton>
      </FormActions>
    </CustomForm>
  );
}

export default SignUpForm;
