import { addItem, updateItem } from '../../actions/itemList';
import { addSimpleNotification } from '../../actions/notifications';
import { Button, PrimaryButton } from '../base/Buttons';
import { clearCurrentItem } from '../../actions/currentItem';
import { clearModal } from '../../actions/modal';
import { FlexBox, InlineBox } from '../base/Box';
import { Form, FormActions, FormHeader, FormInput, FormLabel, FormSelect } from '../base/Form';
import { InfoIcon } from '../base/Icons';
import { useDispatch, useSelector } from 'react-redux';
import { useFormInput } from '../../hooks';
import ListCategories from '../../utils/ListCategories';
import Tippy from '@tippyjs/react';

function ItemForm() {
  const dispatch = useDispatch();
  const item = useSelector((state) => state.currentItem);

  const category = useFormInput(item.category || ListCategories.WANT);
  const isBuyingBlocked = useFormInput(item.isBuyingBlocked || false);
  const name = useFormInput(item.name || '');
  const price = useFormInput(item.price || '');
  const url = useFormInput(item.url || '');

  const action = item.id ? 'Edit' : 'Add';
  const title = item.id ? 'Edit Item' : 'Add Item';

  const handleCancelClick = () => {
    dispatch(clearCurrentItem());
    dispatch(clearModal());
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const isNew = !item.id;

    if (name.value.trim().length === 0) {
      dispatch(addSimpleNotification('Name must not be blank', true));

      return;
    }

    if (isNew) {
      dispatch(
        addItem({
          category: category.value,
          isBuyingBlocked: isBuyingBlocked.value,
          name: name.value,
          price: price.value,
          url: url.value,
        }),
      );
    } else {
      dispatch(
        updateItem(item.id, {
          category: category.value,
          isBuyingBlocked: isBuyingBlocked.value,
          name: name.value,
          price: price.value,
          url: url.value,
        }),
      );
    }
  };

  return (
    <Form data-testid="item-form" onSubmit={handleSubmit}>
      <FormHeader>{title}</FormHeader>

      <FormLabel htmlFor="name" required>
        Name
      </FormLabel>

      <FormInput autoComplete="off" autoFocus {...name} id="name" type="text" />
      <FormLabel htmlFor="price">Price</FormLabel>
      <FormInput autoComplete="off" id="price" type="text" {...price} />

      <FormLabel htmlFor="category">Category</FormLabel>

      <FormSelect id="category" {...category}>
        <option value={ListCategories.NEED}>Need</option>
        <option value={ListCategories.WANT}>Want</option>
        <option value={ListCategories.LIKE}>Like</option>
      </FormSelect>

      <FormLabel htmlFor="url">Url</FormLabel>
      <FormInput autoComplete="off" id="url" type="text" {...url} />

      <div />

      <FlexBox alignItems="center" justifyContent="flex-start">
        <FlexBox mr={2}>
          <FormInput
            checked={isBuyingBlocked.value}
            id="isBuyingBlocked"
            onChange={isBuyingBlocked.onChange}
            type="checkbox"
          />
        </FlexBox>

        <FormLabel htmlFor="isBuyingBlocked">
          <InlineBox mr={1}>Block Buying</InlineBox>

          <Tippy content="Useful for gift cards">
            <InfoIcon height="18px" width="18px" />
          </Tippy>
        </FormLabel>
      </FlexBox>

      <FormActions>
        <PrimaryButton type="submit">{action}</PrimaryButton>

        <Button onClick={handleCancelClick} type="button">
          Cancel
        </Button>
      </FormActions>
    </Form>
  );
}

export default ItemForm;
