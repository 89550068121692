import { CurrentItemActionType } from '../actions/currentItem';
import createReducer from '../utils/createReducer';

const INITIAL_STATE = {};

const reducers = {
  [CurrentItemActionType.CLEAR_CURRENT_ITEM]: () => ({ ...INITIAL_STATE }),
  [CurrentItemActionType.SET_CURRENT_ITEM]: (state, { currentItem }) => ({ ...currentItem }),
};

const currentItemReducer = createReducer(reducers, INITIAL_STATE);

export default currentItemReducer;
