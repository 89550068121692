import { Box } from '../base/Box';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const HeaderLink = styled(NavLink)`
  color: var(--primary-color);
  font-size: 0.9em;
  margin-right: 1rem;
  text-decoration: none;
  transition: color 0.15s ease-in-out;

  :last-child {
    margin-right: 0;
  }

  :hover {
    color: var(--neutral-bg-color--active);
  }

  &.active {
    border-bottom: 2px solid var(--primary-color);
    color: var(--primary-color);
  }
`;

function HeaderLinks() {
  /* -- Hooks -- */
  /* -- Event Handlers -- */
  /* -- Rendering -- */
  return (
    <Box ml={3}>
      <HeaderLink end to="/my-items">
        My Items
      </HeaderLink>

      <HeaderLink end to="/groups">
        Groups
      </HeaderLink>

      <HeaderLink end to="/friends">
        Friends
      </HeaderLink>
    </Box>
  );
}

export default HeaderLinks;
