import { FlexBox } from '../base/Box';
import { MediaQueryContext } from '../../contexts/MediaQueryContext';
import { useContext } from 'react';
import { useSelector } from 'react-redux';
import HeaderActions from './HeaderActions';
import HeaderLinks from './HeaderLinks';
import HeaderTitle from './HeaderTitle';
import styled from 'styled-components';

const Container = styled(FlexBox)`
  background-color: var(--primary-bg-color);
  box-shadow: var(--box-shadow--2);
  color: var(--primary-color);
  font-family: var(--font-family--header, inherit);
  font-size: 1.5rem;
  height: 3.5rem;
  padding: 0 1rem;
`;

function Header() {
  /* -- Hooks -- */
  const { isSmallScreen } = useContext(MediaQueryContext);
  const currentUser = useSelector((state) => state.currentUser);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  return (
    <Container data-testid="header" alignItems="center" justifyContent="space-between">
      <FlexBox alignItems="center">
        <HeaderTitle />
        {!isSmallScreen && currentUser.id && <HeaderLinks />}
      </FlexBox>

      <HeaderActions />
    </Container>
  );
}

export default Header;
