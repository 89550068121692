import { addSimpleNotification } from '../../actions/notifications';
import { Button, PrimaryButton } from '../base/Buttons';
import { clearModal } from '../../actions/modal';
import { Form, FormActions, FormHeader, FormInput, FormLabel } from '../base/Form';
import { useDispatch, useSelector } from 'react-redux';
import { useFormInput } from '../../hooks';
import { useNavigate } from 'react-router-dom';
import groupService from '../../services/groupService';

function GroupForm({ group = {} }) {
  const currentUserId = useSelector((state) => state.currentUser.id);
  const dispatch = useDispatch();
  const nameInput = useFormInput(group.name || '');
  const navigate = useNavigate();

  const action = group.id ? 'Edit' : 'Create';
  const title = group.id ? 'Edit Group' : 'Create Group';

  const handleCancelClick = () => {
    dispatch(clearModal());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const isNew = !group.id;
    const name = nameInput.value.trim();

    if (name.length === 0) {
      dispatch(addSimpleNotification('Name must not be blank', true));

      return;
    }

    if (isNew) {
      const newGroup = await groupService.createGroup(currentUserId, { name });

      dispatch(clearModal());

      navigate(`/groups/${newGroup.id}/friends`);
    }
  };

  return (
    <Form data-testid="group-form" onSubmit={handleSubmit}>
      <FormHeader>{title}</FormHeader>

      <FormLabel htmlFor="name" required>
        Name
      </FormLabel>

      <FormInput autoComplete="off" autoFocus {...nameInput} id="name" type="text" />

      <FormActions>
        <PrimaryButton type="submit">{action}</PrimaryButton>

        <Button onClick={handleCancelClick} type="button">
          Cancel
        </Button>
      </FormActions>
    </Form>
  );
}

export default GroupForm;
