import { AddIcon } from '../components/base/Icons';
import { setModal } from '../actions/modal';
import { useDispatch, useSelector } from 'react-redux';
import Card from '../components/base/Card';
import Fab from '../components/base/Fab';
import ItemForm from '../components/items/ItemForm';
import ItemListing from '../components/items/ItemListing';
import SinglePane from '../components/base/layout/SinglePane';

function MyItemsPage() {
  /* -- Hooks -- */
  const currentUserId = useSelector((state) => state.currentUser.id);
  const dispatch = useDispatch();

  /* -- Event Handlers -- */
  const handleFabClick = () => dispatch(setModal(ItemForm));

  /* -- Rendering -- */
  return (
    <SinglePane>
      <Card mb="64px" p={3}>
        <ItemListing userId={currentUserId} />

        <Fab data-testid="add-item-fab" extended onClick={handleFabClick}>
          <AddIcon />
          <span>Add Item</span>
        </Fab>
      </Card>
    </SinglePane>
  );
}

export default MyItemsPage;
