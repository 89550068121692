import database from '../database/database';
import firebase from '../database/firebase';
import userService from './userService';

const GROUP_DEFAULTS = {};

class GroupService {
  constructor() {
    this.path = 'groups';
  }

  async addUserToGroup(groupId, userId) {
    return database
      .update(this.path, groupId)
      .withValues({ users: firebase.firestore.FieldValue.arrayUnion(userId) })
      .execute();
  }

  async createGroup(ownerId, { name }) {
    const group = await database
      .createDoc(this.path)
      .withValues({ ...GROUP_DEFAULTS, name })
      .execute();

    await userService.addGroupToUser(ownerId, group.id);

    await this.addUserToGroup(group.id, ownerId);

    return group;
  }

  getGroup(groupId) {
    return database.doc(this.path, groupId);
  }

  getUserGroups(userId) {
    return database.query(this.path).where('users', 'array-contains', userId);
  }
}

export default new GroupService();
