import { clearCurrentItem } from './currentItem';
import { clearModal } from './modal';
import { hideSpinner, showSpinner } from './spinner';
import itemService from '../services/itemService';

/* --------------------- */
/* -- Reducer Actions -- */
/* --------------------- */

/* ----------------- */
/* -- API Actions -- */
/* ----------------- */
const addItem = (item) => async (dispatch, getState) => {
  dispatch(showSpinner());

  const { currentUser } = getState();

  try {
    await itemService.addItem({
      ...item,
      userId: currentUser.id,
    });

    dispatch(clearCurrentItem());
    dispatch(clearModal());
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(hideSpinner());
  }
};

const buyItem = (id) => async (dispatch, getState) => {
  dispatch(showSpinner());

  const { currentUser } = getState();

  try {
    await itemService.buyItem(id, currentUser.id);
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(hideSpinner());
  }
};

const deleteItem = (id) => async (dispatch) => {
  dispatch(showSpinner());

  try {
    await itemService.deleteItem(id);

    dispatch(clearModal());
    dispatch(clearCurrentItem());
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(hideSpinner());
  }
};

const unbuyItem = (id) => async (dispatch, getState) => {
  dispatch(showSpinner());

  const { currentUser } = getState();

  try {
    await itemService.unbuyItem(id, currentUser.id);
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(hideSpinner());
  }
};

const updateItem = (id, item) => async (dispatch) => {
  dispatch(showSpinner());

  try {
    await itemService.updateItem(id, item);

    dispatch(clearModal());
    dispatch(clearCurrentItem());
  } catch (e) {
    console.error(e);
  } finally {
    dispatch(hideSpinner());
  }
};

export { addItem, buyItem, deleteItem, unbuyItem, updateItem };
