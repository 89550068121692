class ListenToCollection {
  constructor(db, path, callback) {
    this.callback = callback;
    this.db = db;
    this.path = path;
    this.whereClauses = [];
  }

  where(field, operater, value) {
    this.whereClauses.push({ field, operater, value });

    return this;
  }

  execute() {
    let query = this.db.collection(this.path);

    this.whereClauses.forEach((c) => {
      query = query.where(c.field, c.operater, c.value);
    });

    return query.onSnapshot(this.callback);
  }
}

export default ListenToCollection;
