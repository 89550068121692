import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { CenterBox } from './base/Box';
import {
  FriendsPage,
  GroupPage,
  ItemsPage,
  Login,
  MyItemsPage,
  SignUp,
  UnknownPage,
  UsersPage,
} from '../pages';
import { useSelector } from 'react-redux';
import Footer from './footer/Footer';
import Header from './header/Header';
import Modal from './base/modal/Modal';
import Notifications from './notifications/Notifications';
import QueryStatus from '../utils/QueryStatus';
import Spinner from './spinner/Spinner';
import styled from 'styled-components';
import useAuthState from '../hooks/useAuthState';

const LoggedInRoute = ({ element, ...props }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const location = useLocation();

  return (
    <Route
      {...props}
      element={
        currentUser.id ? element : <Navigate to="/login" state={{ from: location.pathname }} />
      }
    />
  );
};

LoggedInRoute.propTypes = {
  element: PropTypes.node,
};

const LoggedOutRoute = ({ element, ...props }) => {
  const currentUser = useSelector((state) => state.currentUser);
  const location = useLocation();

  const dest = location.state ? location.state.from : '/my-items';

  return <Route {...props} element={currentUser.id ? <Navigate replace to={dest} /> : element} />;
};

LoggedOutRoute.propTypes = {
  element: PropTypes.node,
};

const RootRoute = (props) => {
  const currentUser = useSelector((state) => state.currentUser);

  return (
    <Route
      {...props}
      element={currentUser.id ? <Navigate replace to="/my-items" /> : <Navigate to="/login" />}
    />
  );
};

const LoadingWrapper = styled(CenterBox)`
  --hollow-dots-spinner__color: var(--primary-bg-color);
  --hollow-dots-spinner__size: 1.5rem;

  height: 100vh;
  width: 100vw;
`;

function App() {
  const status = useAuthState();

  return status === QueryStatus.SUCCESS ? (
    <BrowserRouter>
      <Header />

      <Routes>
        <RootRoute path="/" />

        <LoggedOutRoute path="/login" element={<Login />} />
        <LoggedOutRoute path="/signup" element={<SignUp />} />

        <LoggedInRoute path="/friends" element={<FriendsPage />} />
        <LoggedInRoute path="/friends/:userId/items" element={<ItemsPage />} />

        <LoggedInRoute path="/groups" element={<GroupPage />} />
        <LoggedInRoute path="/groups/:groupId/friends" element={<UsersPage />} />

        <LoggedInRoute path="/my-items" element={<MyItemsPage />} />

        <Route path="*" element={<UnknownPage />} />
      </Routes>

      <Modal />
      <Notifications />
      <Footer />
    </BrowserRouter>
  ) : (
    <LoadingWrapper>
      <Spinner />
    </LoadingWrapper>
  );
}

export default App;
