import styled from 'styled-components';

const DropdownItem = styled.div.attrs(() => ({
  'data-testid': 'dropdown-item',
}))`
  background-color: var(--default-bg-color, inherit);
  color: var(--default-color, inherit);
  cursor: pointer;
  display: block;
  padding: 0.5rem 0.75rem;
  transition: all 0.2s;
  white-space: nowrap;

  :hover {
    background-color: var(--default-bg-color--hover, #eaeaea);
  }
`;

export default DropdownItem;
