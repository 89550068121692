import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  /* @font-face {
    font-display: optional;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'),
        local('OpenSans-Regular'),
        url(https://fonts.gstatic.com/s/opensans/v17/mem8YaGs126MiZpBA-UFVZ0b.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  }

  @font-face {
    font-display: optional;
    font-family: 'PT Serif';
    font-style: normal;
    font-weight: 400;
    src: local('PT Serif'),
        local('PTSerif-Regular'),
        url(https://fonts.gstatic.com/s/ptserif/v11/EJRVQgYoZZY2vCFuvAFWzr8.woff2)
        format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  } */

  :root {
    /* -- Color Palette -- */
    --default-bg-color: #ffffff;
    --default-bg-color--hover: #eaeaea;
    --default-color: #212121;

    --danger-bg-color--active: hsl(0, 100%, 38%);
    --danger-bg-color--hover: hsl(0, 100%, 57%);
    --danger-bg-color: hsl(0, 91%, 66%);
    --danger-color: #212121;

    --neutral-bg-color--active: hsl(211, 9%, 74%);
    --neutral-bg-color--hover: hsl(211, 14%, 79%);
    --neutral-bg-color: hsl(211, 19%, 84%);
    --neutral-color: #212121;

    --primary-bg-color--active: hsl(200, 100%, 38%);
    --primary-bg-color--hover: hsl(200, 100%, 43%);
    --primary-bg-color: hsl(200, 90%, 56%);
    --primary-color: #ffffff;

    --box-shadow--1: 0 2px 2px 0    rgba(0, 0, 0, 0.14),
                    0 3px 1px -2px rgba(0, 0, 0, 0.12),
                    0 1px 5px 0    rgba(0, 0, 0, 0.2);
    --box-shadow--2: 0 4px 5px 0    rgba(0, 0, 0, 0.14),
                    0 1px 10px 0   rgba(0, 0, 0, 0.12),
                    0 2px 4px -1px rgba(0, 0, 0, 0.3);
    --box-shadow--3: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
                    0 3px 14px 2px rgba(0, 0, 0, 0.12),
                    0 5px 5px -3px rgba(0, 0, 0, 0.2);

    /* -- App Styles -- */
    --bg-color: #e9ecef;

    --font-family--body: "Open Sans", sans-serif;
    --font-family--header: "PT Serif", sans-serif;

    --footer-color: var(--default-color);
    --footer-color--active: var(--primary-bg-color--active);
    --footer-shadow: 0 1px 5px 0    rgba(0, 0, 0, 0.14),
                       0 -2px 10px 0   rgba(0, 0, 0, 0.12),
                       0 -1px 4px -1px rgba(0, 0, 0, 0.3);

    --input-border-color: #dddddd;
    --input-bg-color: #ffffff;

    --link-color: hsl(215, 100%, 42%);
  }

  *,
  *::after,
  *::before {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  a {
    color: var(--link-color);
  }

  body {
    background-color: var(--bg-color);
    color: var(--default-color);
    font-family: var(--font-family--body);
    font-size: 1.15rem;
    margin-bottom: 5rem; // Footer height
    transition: background-color .2s;

    &.dark {
      --default-bg-color: #454545;
      --default-bg-color--hover: #535353;
      --default-color: #fafafa;

      --neutral-bg-color--active: hsl(211, 13%, 78%);
      --neutral-bg-color--hover: hsl(211, 11%, 73%);
      --neutral-bg-color: hsl(211, 9%, 68%);
      --neutral-color: #212121;

      --primary-bg-color--active: hsl(200, 77%, 56%);
      --primary-bg-color--hover: hsl(200, 67%, 50%);
      --primary-bg-color: hsl(200, 67%, 44%);
      --primary-color: #ffffff;

      --box-shadow--1: 0 2px 2px 0    rgba(0, 0, 0, 0.14),
                       0 3px 1px -2px rgba(0, 0, 0, 0.12),
                       0 1px 5px 0    rgba(0, 0, 0, 0.2);
      --box-shadow--2: 0 4px 5px 0    rgba(0, 0, 0, 0.14),
                       0 1px 10px 0   rgba(0, 0, 0, 0.12),
                       0 2px 4px -1px rgba(0, 0, 0, 0.3);
      --box-shadow--3: 0 8px 17px 2px rgba(0, 0, 0, 0.14),
                       0 3px 14px 2px rgba(0, 0, 0, 0.12),
                       0 5px 5px -3px rgba(0, 0, 0, 0.2);

      /* -- App Styles -- */
      --bg-color: #2e2e2e;

      --footer-color: var(--default-color);
      --footer-color--active: var(--primary-bg-color--active);

      --input-border-color: #c3c3c3;
      --input-bg-color: #454545;
      --input-color: #ffffff;

      --link-color: hsl(206, 96%, 77%);
    }
  }

  @media screen and (min-width: 768px) {
    body {
      margin-bottom: 0;
      min-height: 100vh;
    }
  }

  button {
    font-family: var(--font-family--body);
  }

  svg {
    fill: currentColor;
  }
`;

export default GlobalStyles;
