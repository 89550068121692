import { addSimpleNotification } from '../../actions/notifications';
import { Button, PrimaryButton } from '../base/Buttons';
import { clearModal } from '../../actions/modal';
import { Form, FormActions, FormHeader, FormInput, FormLabel } from '../base/Form';
import { updatePassword } from '../../actions/authentication';
import { useDispatch } from 'react-redux';
import { useFormInput } from '../../hooks';
import styled from 'styled-components';

const CustomForm = styled(Form)`
  @media screen and (min-width: 768px) {
    grid-template-columns: 3fr 5fr;
  }
`;

function PasswordForm() {
  const dispatch = useDispatch();
  const confirmPassword = useFormInput('');
  const currentPassword = useFormInput('');
  const newPassword = useFormInput('');

  const handleCancelClick = () => {
    dispatch(clearModal());
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (currentPassword.value.trim().length === 0) {
      dispatch(addSimpleNotification('Missing current password', true));

      return;
    }

    if (newPassword.value.trim().length === 0) {
      dispatch(addSimpleNotification('Missing new password', true));

      return;
    }

    if (newPassword.value !== confirmPassword.value) {
      dispatch(addSimpleNotification('Passwords must match', true));

      return;
    }

    dispatch(updatePassword(currentPassword.value, newPassword.value));
  };

  return (
    <CustomForm data-testid="password-form" onSubmit={handleFormSubmit}>
      <FormHeader>Change Password</FormHeader>

      <FormLabel htmlFor="current">Current Password</FormLabel>
      <FormInput autoFocus {...currentPassword} id="current" type="password" />

      <FormLabel htmlFor="new">New Password</FormLabel>
      <FormInput {...newPassword} id="new" type="password" />

      <FormLabel htmlFor="confirm">Confirm Password</FormLabel>
      <FormInput {...confirmPassword} id="confirm" type="password" />

      <FormActions>
        <PrimaryButton type="submit">Submit</PrimaryButton>

        <Button type="button" onClick={handleCancelClick}>
          Cancel
        </Button>
      </FormActions>
    </CustomForm>
  );
}

export default PasswordForm;
