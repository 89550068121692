import ItemListingItem from './ItemListingItem';
import styled from 'styled-components';

const Title = styled.h3`
  font-family: var(--font-family--header);
  font-weight: normal;
  margin-bottom: 0.5rem;
  text-decoration: underline;
`;

const EmptyItem = styled.div`
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  font-style: italic;
  padding: 0.5rem;
  transition: background-color 0.3s;

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

function constructItemList(list) {
  return Object.entries(list).map(([k, v]) => {
    const item = { ...v, id: k };

    return <ItemListingItem item={item} key={k} />;
  });
}

function ItemListingCategory({ items, title, ...props }) {
  const itemList = constructItemList(items);

  return (
    <div {...props}>
      <Title>{title}</Title>
      {itemList.length > 0 ? itemList : <EmptyItem>None</EmptyItem>}
    </div>
  );
}

ItemListingCategory.propTypes = {
  items: PropTypes.objectOf(PropTypes.object).isRequired,
  title: PropTypes.string.isRequired,
};

export default ItemListingCategory;
