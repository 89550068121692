import Card from '../components/base/Card';
import SinglePane from '../components/base/layout/SinglePane';

const { Link } = require('react-router-dom');

function UnknownPage() {
  /* -- Hooks -- */
  /* -- Event Handlers -- */
  /* -- Rendering -- */
  return (
    <SinglePane>
      <Card p={3}>
        <p>
          Looks like you&apos;ve found a non-existent page! <br />
          Let&apos;s <Link to="/">head back home</Link>
        </p>
      </Card>
    </SinglePane>
  );
}

export default UnknownPage;
