import { addSimpleNotification } from './notifications';
import { clearModal } from './modal';
import { hideSpinner, showSpinner } from './spinner';
import authentication from '../services/authentication';

/* ----------------- */
/* -- API Actions -- */
/* ----------------- */
const logIn = (email, password) => async (dispatch) => {
  dispatch(showSpinner());

  try {
    await authentication.logInWithEmail(email, password);
  } catch (e) {
    switch (e.code) {
      case 'auth/invalid-email':
      case 'auth/user-not-found':
      case 'auth/wrong-password':
        dispatch(addSimpleNotification('Invalid username/password', true));

        break;

      default:
        console.error(e.code);

        dispatch(addSimpleNotification('Unknown error occurred', true));
    }
  } finally {
    dispatch(hideSpinner());
  }
};

const logOut = () => () => authentication.logOut();

const signUp = (name, email, password) => async (dispatch) => {
  dispatch(showSpinner());

  try {
    await authentication.signUp(name, email, password);
  } catch (e) {
    switch (e.code) {
      case 'auth/weak-password':
        dispatch(addSimpleNotification('Password must be at least 6 characters', true));

        break;

      default:
        console.error(e.code);

        dispatch(addSimpleNotification('Unknown error occurred', true));
    }
  } finally {
    dispatch(hideSpinner());
  }
};

const updatePassword = (currentPassword, newPassword) => async (dispatch) => {
  dispatch(showSpinner());

  try {
    await authentication.updatePassword(currentPassword, newPassword);

    dispatch(clearModal());
    dispatch(addSimpleNotification('Password successfully changed'));
  } catch (e) {
    switch (e.code) {
      case 'auth/wrong-password':
        dispatch(addSimpleNotification('Password is incorrect', true));

        break;

      default:
        dispatch(addSimpleNotification(e.message));
    }
  } finally {
    dispatch(hideSpinner());
  }
};

export { logIn, logOut, signUp, updatePassword };
