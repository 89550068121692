import styled, { css } from 'styled-components';

export default styled.button`
  align-items: center;
  background-color: var(--primary-bg-color, #1e88e5);
  border-radius: 2rem;
  border: none;
  box-shadow: var(--box-shadow--1);
  color: var(--primary-color, #fafafa);
  display: flex;
  font-size: 1rem;
  justify-content: center;
  transition: all 0.25s;
  white-space: nowrap;
  bottom: calc(24px + 5rem); // 5rem for footer
  height: 48px;
  left: calc(50% - 24px);
  position: fixed;
  width: 48px;

  :focus,
  :hover {
    background-color: var(--primary-bg-color--hover, #1976d2);
    box-shadow: var(--box-shadow--2);
    cursor: pointer;
    outline: none;
  }

  :active {
    background-color: var(--primary-bg-color--active, #005cb2);
  }

  svg {
    height: 24px;
    width: 24px;
  }

  ${({ extended }) =>
    extended &&
    css`
      left: calc(50% - 74px);
      width: 148px;

      svg {
        margin-right: 4px;
      }
    `}

  @media screen and (min-width: 768px) {
    bottom: 48px;
    right: 48px;
    left: unset;
  }
`;
