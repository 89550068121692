import { Link } from 'react-router-dom';
import Card from '../base/Card';
import styled from 'styled-components';

const Container = styled.div`
  align-items: stretch;
  display: grid;
  grid-auto-rows: 200px;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
`;

const GroupListingItem = styled(Card)`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  transition: box-shadow 0.15s ease-in-out;

  :hover {
    box-shadow: var(--box-shadow--3);
  }
`;

function GroupListing({ groups }) {
  /* -- Hooks -- */
  /* -- Event Handlers -- */
  /* -- Rendering -- */
  const groupListing = groups.map((g) => (
    <Link key={g.id} to={`${g.id}/friends`}>
      <GroupListingItem>{g.name}</GroupListingItem>
    </Link>
  ));

  return <Container>{groupListing}</Container>;
}

GroupListing.propTypes = {
  groups: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    }),
  ),
};

export default GroupListing;
