import { FlexBox } from '../base/Box';
import { useSelector } from 'react-redux';
import Spinner from '../spinner/Spinner';
import styled from 'styled-components';
import UnstyledLink from '../base/unstyled/UnstyledLink';

const Title = styled(FlexBox)`
  --hollow-dots-spinner__color: var(--primary-color);
  --hollow-dots-spinner__size: 10px;

  @media screen and (min-width: 768px) {
    --hollow-dots-spinner__size: 15px;
  }
`;

const UnstyledTitle = styled(UnstyledLink)`
  font-size: 2rem;
  margin-right: 1rem;
`;

function HeaderTitle() {
  const isSpinnerVisible = useSelector((state) => state.isSpinnerVisible);

  return (
    <Title alignItems="center">
      <UnstyledTitle to="/">Wishlist</UnstyledTitle>
      {isSpinnerVisible && <Spinner />}
    </Title>
  );
}

export default HeaderTitle;
