import { useEffect, useState } from 'react';
import QueryStatus from '../utils/QueryStatus';

const defaultOptions = {};

function useFirebaseCollection(query, options = defaultOptions) {
  const [data, setData] = useState();
  const [status, setStatus] = useState(QueryStatus.LOADING);

  useEffect(() => {
    setStatus(QueryStatus.LOADING);

    if (!query) {
      setData(null);
      setStatus(QueryStatus.SUCCESS);

      return null;
    }

    const unsubscribe = query.onSnapshot(
      options,
      (snapshot) => {
        setData(snapshot);
        setStatus(QueryStatus.SUCCESS);
      },
      (error) => {
        setData(error);
        setStatus(QueryStatus.ERROR);
      },
    );

    return unsubscribe;
  }, [options, query]);

  return { data, status };
}

export default useFirebaseCollection;
