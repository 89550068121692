import { clearCurrentUser, setCurrentUser } from '../actions/currentUser';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import firebase from '../database/firebase';
import QueryStatus from '../utils/QueryStatus';
import userService from '../services/userService';

function useAuthState() {
  const [status, setStatus] = useState(QueryStatus.LOADING);
  const dispatch = useDispatch();

  firebase.auth().onAuthStateChanged(async (user) => {
    if (user) {
      const _user = await userService.getUser(user.uid);

      dispatch(setCurrentUser(_user));
    } else {
      dispatch(clearCurrentUser());
    }

    setStatus(QueryStatus.SUCCESS);
  });

  return status;
}

export default useAuthState;
