import database from '../database/database';
import firebase from '../database/firebase';

class UserService {
  constructor() {
    this.path = 'users';
  }

  async addGroupToUser(userId, groupId) {
    return database
      .update(this.path, userId)
      .withValues({ groups: firebase.firestore.FieldValue.arrayUnion(groupId) })
      .execute();
  }

  getFriends(groups) {
    if (!groups || groups.length === 0) return null;

    return database.query(this.path).where('groups', 'array-contains-any', groups);
  }

  getGroupUsers(groupId) {
    return database.query(this.path).where('groups', 'array-contains', groupId);
  }

  getUser2(id) {
    return database.doc(this.path, id);
  }

  async getUserList() {
    return database.queryCollection(this.path).execute();
  }

  async getUser(id) {
    return database.queryDoc(this.path, id).execute();
  }
}

export default new UserService();
