import { FlexBox } from '../base/Box';
import { Link } from 'react-router-dom';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import Card from '../base/Card';
import SearchInput from '../base/SearchInput';
import styled from 'styled-components';

const UserLink = styled(Link)`
  align-items: center;
  border-bottom: 1px solid var(--default-bg-color--hover, #aaaaaa);
  color: var(--default-color);
  display: flex;
  line-height: 2rem;
  padding: 0.75rem;
  text-decoration: none;
  transition: all 0.25s;

  :last-child {
    border-bottom-color: transparent;
  }

  :focus,
  :hover {
    background-color: var(--default-bg-color--hover, #eaeaea);
    outline: none;
  }
`;

function constructUserList(users) {
  return users
    .sort(({ name: name1 }, { name: name2 }) => {
      if (name1.toLowerCase() > name2.toLowerCase()) return 1;
      if (name1.toLowerCase() < name2.toLowerCase()) return -1;

      return 0;
    })
    .map(({ id, name }) => (
      <UserLink data-testid="user-list-item" key={id} to={`/friends/${id}/items`}>
        {name}
      </UserLink>
    ));
}

function filterUsers(users, term, currentUserId) {
  const lowerCaseTerm = term.toLowerCase();

  return users
    .map((u) => {
      if (u.id === currentUserId) return null;

      return u.name.toLowerCase().indexOf(lowerCaseTerm) > -1 ? u : null;
    })
    .filter(Boolean);
}

function UserListing({ users }) {
  /* -- Hooks -- */
  const [term, setTerm] = useState('');
  const currentUserId = useSelector((state) => state.currentUser.id);
  const listRef = useRef();
  const searchRef = useRef();

  /* -- Event Handlers -- */
  const handleKeyDown = (e) => {
    switch (e.keyCode) {
      case 38: // Up arrow
        if (!e.target.previousElementSibling) {
          searchRef.current.focus();
        } else {
          e.target.previousElementSibling.focus();
        }

        break;
      case 40: // Down arrow
        if (e.target.nextElementSibling) {
          e.target.nextElementSibling.focus();
        } else if (e.target.id === 'search') {
          listRef.current.children[0].focus();
        }

        break;

      default:
    }
  };

  const handleSearchInput = (e) => setTerm(e.target.value);

  /* -- Rendering -- */
  const filteredUsers = filterUsers(users, term, currentUserId);

  const userList = constructUserList(filteredUsers);

  return (
    <div data-testid="user-listing">
      <Card onKeyDown={handleKeyDown}>
        <FlexBox p={2} onFocus={() => searchRef.current.focus()} tabindex="-1">
          <SearchInput
            autoComplete="off"
            data-testid="search"
            id="search"
            onChange={handleSearchInput}
            placeholder="Search..."
            ref={searchRef}
            type="text"
            value={term}
          />
        </FlexBox>

        <div ref={listRef}>{userList}</div>
      </Card>
    </div>
  );
}

UserListing.propTypes = {
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ),
};

export default UserListing;
