const CurrentItemActionType = {
  CLEAR_CURRENT_ITEM: 'current_item:CLEAR',
  SET_CURRENT_ITEM: 'current_item:SET',
};

/* --------------------- */
/* -- Reducer Actions -- */
/* --------------------- */
const clearCurrentItem = () => ({ type: CurrentItemActionType.CLEAR_CURRENT_ITEM });
const setCurrentItem = (currentItem) => ({
  currentItem,
  type: CurrentItemActionType.SET_CURRENT_ITEM,
});

export { clearCurrentItem, CurrentItemActionType, setCurrentItem };
