import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const Container = styled(NavLink)`
  align-items: center;
  color: var(--footer-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-decoration: none;
  transition: all 0.15s ease-in-out;

  :active {
    background-color: var(--footer-bg-color--active);
  }

  &.active {
    background-color: inherit;
    color: var(--footer-color--active);
  }
`;

function FooterLink({ children, ...props }) {
  /* -- Hooks -- */
  /* -- Event Handlers -- */
  /* -- Rendering -- */
  return <Container {...props}>{children}</Container>;
}

FooterLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FooterLink;
