import './service-worker/sw-installer';
import 'tippy.js/dist/tippy';
import App from './components/App';
import GlobalStyles from './components/GlobalStyles';
import Providers from './components/Providers';
import ReactDOM from 'react-dom';

(() => {
  const Root = () => (
    <Providers>
      <GlobalStyles />
      <App />
    </Providers>
  );

  ReactDOM.render(<Root />, document.querySelector('main'));
})();
