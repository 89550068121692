import { Button, PrimaryButton } from '../base/Buttons';
import { BuyIcon, DeleteIcon, EditIcon, UnbuyIcon } from '../base/Icons';
import { buyItem, unbuyItem } from '../../actions/itemList';
import { FlexBox } from '../base/Box';
import { MediaQueryContext } from '../../contexts/MediaQueryContext';
import { setCurrentItem } from '../../actions/currentItem';
import { setModal } from '../../actions/modal';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import ConfirmDelete from '../confirm-delete/ConfirmDelete';
import ItemForm from './ItemForm';

function ItemListItemActions({ isBuyingBlocked, isBuyer, isBought, isCurrentUser, item }) {
  const { isSmallScreen } = useContext(MediaQueryContext);
  const dispatch = useDispatch();

  const buyHandler = () => dispatch(buyItem(item.id));
  const unbuyHandler = () => dispatch(unbuyItem(item.id));

  const editHandler = () => {
    dispatch(setCurrentItem(item));

    dispatch(setModal(ItemForm));
  };

  const deleteHandler = () => {
    dispatch(setCurrentItem(item));

    dispatch(setModal(ConfirmDelete));
  };

  if (isCurrentUser) {
    return (
      <FlexBox alignItems="center">
        <PrimaryButton isIconButton={isSmallScreen} mr={3} onClick={editHandler} type="button">
          {isSmallScreen ? <EditIcon /> : 'Edit'}
        </PrimaryButton>

        <Button isIconButton={isSmallScreen} onClick={deleteHandler} type="button">
          {isSmallScreen ? <DeleteIcon /> : 'Delete'}
        </Button>
      </FlexBox>
    );
  }

  if (isBuyingBlocked) return null;

  if (isBought) {
    return (
      isBuyer && (
        <Button isIconButton={isSmallScreen} onClick={unbuyHandler} type="button">
          {isSmallScreen ? <UnbuyIcon /> : 'Un-Buy'}
        </Button>
      )
    );
  }

  return (
    <Button isIconButton={isSmallScreen} onClick={buyHandler} type="button">
      {isSmallScreen ? <BuyIcon /> : 'Buy'}
    </Button>
  );
}

ItemListItemActions.propTypes = {
  isBought: PropTypes.bool,
  isBuyer: PropTypes.bool,
  isBuyingBlocked: PropTypes.bool,
  isCurrentUser: PropTypes.bool,

  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }),
};

export default ItemListItemActions;
