import { FormInput } from './Form';
import styled from 'styled-components';

const SearchInput = styled(FormInput)`
  border-bottom-color: var(--input-border-color);
  border-left-color: transparent;
  border-radius: 0;
  border-right-color: transparent;
  border-top-color: transparent;
  height: 2.5rem;
  line-height: 2.5rem;
  width: 100%;

  :focus {
    border-radius: 0.25rem;
  }

  ::placeholder {
    color: var(--neutral-bg-color--active);
  }
`;

export default SearchInput;
