import database from '../database/database';
import firebase from '../database/firebase';

class Authentication {
  constructor() {
    this._auth = firebase.auth();
  }

  getUser() {
    return this._auth.currentUser;
  }

  async logInWithEmail(email, password) {
    return this._auth.signInWithEmailAndPassword(email, password);
  }

  logOut() {
    this._auth.signOut();
  }

  async signUp(name, email, password) {
    const userRef = await this._auth.createUserWithEmailAndPassword(email, password);

    database.createDoc('users').withKey(userRef.user.uid).withValues({ name }).execute();
  }

  async updatePassword(currentPassword, newPassword) {
    const credential = firebase.auth.EmailAuthProvider.credential(
      this._auth.currentUser.email,
      currentPassword,
    );

    await this._auth.currentUser.reauthenticateWithCredential(credential);

    await this._auth.currentUser.updatePassword(newPassword);
  }
}

export default new Authentication();
