/* eslint-disable import/extensions */
import firebase from 'firebase/app';
// Must be last
import 'firebase/auth';
import 'firebase/firestore';
/* eslint-enable import/extensions */

firebase.initializeApp(FIREBASE_CONFIG);
firebase.firestore().enablePersistence();

export default firebase;
