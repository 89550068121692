import { FlexBox, InlineBox } from '../base/Box';
import { LockIcon } from '../base/Icons';
import { useSelector } from 'react-redux';
import ItemListItemActions from './ItemListItemActions';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';

const Item = styled(FlexBox)`
  border-radius: 2rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  transition: background-color 0.3s;

  :hover {
    background-color: var(--default-bg-color--hover);
  }

  div:first-child {
    ${({ isBought }) =>
      isBought &&
      css`
        font-style: italic;
        text-decoration: line-through;
      `}
  }

  @media screen and (min-width: 768px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

function ItemListingItem({ item }) {
  const { id } = useSelector((state) => state.currentUser);
  const { buyerId, isBuyingBlocked, isBought, name, price, url, userId } = item;

  const isBuyer = buyerId === id;
  const isCurrentUser = userId === id;

  const _price = price && <span data-testid="price">(${price})</span>;

  const _name = url ? (
    <a data-testid="name" href={url} rel="noopener noreferrer" target="_blank">
      {name}
    </a>
  ) : (
    <span data-testid="name">{name}</span>
  );

  return (
    <Item
      data-testid="item-list-item"
      alignItems="center"
      isBought={!isCurrentUser && isBought}
      justifyContent="space-between"
    >
      <FlexBox alignItems="center">
        <InlineBox mr={1}>
          {_name} {_price}
        </InlineBox>

        {isBuyingBlocked && (
          <Tippy content="Buying is blocked for this item">
            <LockIcon height="18px" width="18px" />
          </Tippy>
        )}
      </FlexBox>

      <ItemListItemActions
        isBought={isBought}
        isBuyingBlocked={isBuyingBlocked}
        isBuyer={isBuyer}
        isCurrentUser={isCurrentUser}
        item={item}
      />
    </Item>
  );
}

ItemListingItem.propTypes = {
  item: PropTypes.shape({
    buyerId: PropTypes.string,
    isBought: PropTypes.bool,
    isBuyingBlocked: PropTypes.bool,
    name: PropTypes.string.isRequired,
    price: PropTypes.string,
    url: PropTypes.string,
    userId: PropTypes.string.isRequired,
  }),
};

export default ItemListingItem;
