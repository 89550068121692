import { Box } from '../components/base/Box';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import groupService from '../services/groupService';
import QueryStatus from '../utils/QueryStatus';
import SinglePane from '../components/base/layout/SinglePane';
import Spinner from '../components/spinner/Spinner';
import styled from 'styled-components';
import useFirebaseCollection from '../hooks/useFirebaseCollection';
import useFirebaseDocData from '../hooks/useFirebaseDocData';
import UserListing from '../components/users/UserListing';
import userService from '../services/userService';

const Title = styled.h1`
  font-family: var(--font-family--header);
  font-weight: normal;
`;

function UsersPage() {
  /* -- Hooks -- */
  const { groupId } = useParams();

  const getGroup = useMemo(() => groupService.getGroup(groupId), [groupId]);
  const queryGroupUsers = useMemo(() => userService.getGroupUsers(groupId), [groupId]);

  const { data, status } = useFirebaseCollection(queryGroupUsers);
  const { data: group, status: groupStatus } = useFirebaseDocData(getGroup);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  let title = 'Loading...';

  if (groupStatus === QueryStatus.SUCCESS) {
    title = group.name;
  }

  switch (status) {
    case QueryStatus.LOADING:
      return <Spinner />;

    case QueryStatus.ERROR:
      return <div>Error!</div>;

    default: {
      const users = data.docs.map((doc) => {
        const { name } = doc.data();

        return {
          id: doc.id,
          name,
        };
      });

      return (
        <SinglePane>
          <Box mb={3}>
            <Title>{title}</Title>
          </Box>

          <UserListing users={users} />
        </SinglePane>
      );
    }
  }
}

export default UsersPage;
