import { Box } from '../components/base/Box';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Card from '../components/base/Card';
import ItemListing from '../components/items/ItemListing';
import QueryStatus from '../utils/QueryStatus';
import SinglePane from '../components/base/layout/SinglePane';
import styled from 'styled-components';
import useFirebaseDocData from '../hooks/useFirebaseDocData';
import userService from '../services/userService';

const Title = styled.h1`
  font-family: var(--font-family--header);
  font-weight: normal;
`;

function ItemsPage() {
  /* -- Hooks -- */
  const { userId } = useParams();
  const getUser = useMemo(() => userService.getUser2(userId), [userId]);

  const { data, status } = useFirebaseDocData(getUser);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  let title = 'Loading...';

  if (status === QueryStatus.SUCCESS) {
    title = `${data.name} Items`;
  }

  return (
    <SinglePane>
      <Box mb={3}>
        <Title>{title}</Title>
      </Box>

      <Card p={3}>
        <ItemListing userId={userId} />
      </Card>
    </SinglePane>
  );
}

export default ItemsPage;
