import { Form, FormActions, FormHeader, FormInput, FormLabel } from '../base/Form';
import { logIn } from '../../actions/authentication';
import { PrimaryButton } from '../base/Buttons';
import { useDispatch } from 'react-redux';
import { useFormInput } from '../../hooks';

function LoginForm() {
  const dispatch = useDispatch();
  const email = useFormInput('');
  const password = useFormInput('');

  const handleSubmit = (e) => {
    e.preventDefault();

    dispatch(logIn(email.value, password.value));
  };

  return (
    <Form data-testid="login-form" onSubmit={handleSubmit}>
      <FormHeader>Login</FormHeader>

      <FormLabel htmlFor="email" required>
        Email
      </FormLabel>

      <FormInput autoFocus {...email} id="email" type="text" />

      <FormLabel htmlFor="password" required>
        Password
      </FormLabel>

      <FormInput {...password} id="password" type="password" />

      <FormActions>
        <PrimaryButton primary type="submit">
          Submit
        </PrimaryButton>
      </FormActions>
    </Form>
  );
}

export default LoginForm;
