import { combineReducers } from 'redux';
import currentItem from './reducers/currentItem';
import currentUser from './reducers/currentUser';
import modal from './reducers/modal';
import notifications from './reducers/notifications';
import spinner from './reducers/spinner';

export default combineReducers({
  currentItem,
  currentUser,
  isSpinnerVisible: spinner,
  modal,
  notifications,
});
