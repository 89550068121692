import { FriendsIcon, GroupIcon, ListIcon } from '../base/Icons';
import { MediaQueryContext } from '../../contexts/MediaQueryContext';
import { useContext } from 'react';
import FooterLink from './FooterLink';
import styled from 'styled-components';

const Container = styled.div`
  background-color: var(--bg-color);
  bottom: 0;
  box-shadow: var(--footer-shadow);
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  height: 5rem;
  left: 0;
  position: fixed;
  right: 0;
`;

function Footer() {
  /* -- Hooks -- */
  const { isSmallScreen } = useContext(MediaQueryContext);

  /* -- Event Handlers -- */
  /* -- Rendering -- */
  return isSmallScreen ? (
    <Container>
      <FooterLink end to="/my-items">
        <span>My Items</span>
        <ListIcon />
      </FooterLink>

      <FooterLink end to="/groups">
        <span>Groups</span>
        <GroupIcon />
      </FooterLink>

      <FooterLink end to="/friends">
        <span>Friends</span>
        <FriendsIcon />
      </FooterLink>
    </Container>
  ) : null;
}

export default Footer;
