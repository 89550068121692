import { Box } from '../components/base/Box';
import { hideSpinner } from '../actions/spinner';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import Card from '../components/base/Card';
import SignUpForm from '../components/signup/SignUpForm';
import SinglePane from '../components/base/layout/SinglePane';

function SignUp() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(hideSpinner());
  }, [dispatch]);

  return (
    <Box py={3}>
      <SinglePane>
        <Card animate p={3}>
          <SignUpForm />
        </Card>
      </SinglePane>
    </Box>
  );
}

export default SignUp;
